import React from 'react'

 
export default class AppointmentButton extends React.Component {
  constructor(props) {
    super(props);
  }
 
  onClick = (e) => {
    var Calendly = window.Calendly || null;
    if(Calendly){
      Calendly.showPopupWidget('https://calendly.com/cloudaccountant')
    };
    return false;
  }
 
  render() {
    return (
      <button 
        className={this.props.className} type="button" onClick={() => {this.onClick()}}
        title={this.props.title}
      >
      </button>
    )
  }
}