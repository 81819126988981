import React from 'react'
import { StaticQuery, Link, graphql } from "gatsby"
import registerStyles from '../styles/register.module.scss'
import axios from 'axios';
import ReactDOM from "react-dom";



const Register = (props) => (

  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: {page: {eq: "index"}}) {
          frontmatter {
            phoneOne
            registerHeader
            registerStep1Text
            registerStep1Subtext
            registerStep2Text
            registerStep2Subtext
            registerStep3Text
            registerStep3Subtext
            registerFormHeader
            registerFormSubheader
            registerFormNameLabel
            registerFormEmailLabel
            registerFormEmailAddress1
            registerFormEmailAddress2
            registerFormHelpTextLine1
            registerFormHelpTextLine2
            registerFormConfirmationHeader
            registerFormConfirmationSubheader
          }
        }
      }
      `
    }
    render={(data) => {
      const md = data.markdownRemark.frontmatter;
      const phoneOne = "tel:"+md.phoneOne;

      const handleSubmit = (e) => {
        e.preventDefault();
        console.log('submit form');
        const form = document.querySelector('.'+registerStyles.registerOverlayFormWrap + ' form');
        const content = {
          'text-1523092431618' : form.elements[2].value,
          'text-1523092465522': form.elements[4].value
        }

        sendMailto(content);
        //sendMailto(md.registerFormEmailAddress2,form.elements[2].value,form.elements[4].value,form.elements[0].value);
        const formWrap = document.querySelector('.'+registerStyles.registerOverlayFormWrap);
        const confirmationWrap = document.querySelector('.'+registerStyles.registerOverlayConfirmationWrap);
        formWrap.classList.add(registerStyles.hidden);
        confirmationWrap.classList.remove(registerStyles.hidden);
    
        return false;
      }

      const sendMailto = ( content ) => {
        axios.post('https://mailthis.to/g.mcdaniel@us.edenspiekermann.com', content);
        axios.post('https://kontactr.com/form/edff05b6ef6a8f1', content);
        return false;
        axios({
          method : 'post',
          config: { headers: {'Content-Type': 'multipart/form-data' }},
          url : 'https://kontactr.com/form/edff05b6ef6a8f1',
          data : content
        });
      }

      const closeOverlay = (e) => {
        const overlayWrap = document.querySelector('.'+registerStyles.registerOverlayMainWrap);
        const body = document.getElementsByTagName('body');
        body[0].classList.remove('overlay');
        overlayWrap.classList.add(registerStyles.hidden);
      }
     

      return (
        <div className={registerStyles.hidden + " " +registerStyles.registerOverlayMainWrap}>
          <div className={registerStyles.registerOverlayContentWrap}>
            <a href="javascript:void(0)" onClick={closeOverlay} title="Close Overlay" className={registerStyles.registerOverlayIconClose}></a>
            <div className={registerStyles.registerOverlayInstructionsWrap}>
              <h4>{md.registerHeader}</h4>
              <div className={registerStyles.registerStep + ' ' + registerStyles.registerOverlayInstructionsStep1}>
                <div className={registerStyles.registerOverlayInstructionsIcon}></div>
                <div className={registerStyles.registerOverlayInstructionsTextWrap}>
                  <p className={registerStyles.registerOverlayInstructionsText}>{md.registerStep1Text}</p>
                  <p className={registerStyles.registerOverlayInstructionsSubtext}>{md.registerStep1Subtext}</p>
                </div>
              </div>

              <div className={registerStyles.registerStep + ' ' + registerStyles.registerOverlayInstructionsStep2}>
                <div className={registerStyles.registerOverlayInstructionsIcon}></div>
                <div className={registerStyles.registerOverlayInstructionsTextWrap}>
                  <p className={registerStyles.registerOverlayInstructionsText}>{md.registerStep2Text}</p>
                  <p className={registerStyles.registerOverlayInstructionsSubtext}>{md.registerStep2Subtext}</p>
                </div>
              </div>

              <div className={registerStyles.registerStep + ' ' + registerStyles.registerOverlayInstructionsStep3}>
                <div className={registerStyles.registerOverlayInstructionsIcon}></div>
                <div className={registerStyles.registerOverlayInstructionsTextWrap}>
                  <p className={registerStyles.registerOverlayInstructionsText}>{md.registerStep3Text}</p>
                  <p className={registerStyles.registerOverlayInstructionsSubtext}>{md.registerStep3Subtext}</p>
                </div>
              </div>

            </div>
            <div className={registerStyles.registerOverlayFormWrap}>
              <div className={registerStyles.registerOverlayFormContentWrap}>

                <h4>{md.registerFormHeader}</h4>
                <p className={registerStyles.registerOverlayFormSubheader}>{md.registerFormSubheader}</p>
                <iframe src="https://kontactr.com/form/edff05b6ef6a8f1" scrolling="no"></iframe>
  {/*
                <form method="POST" encType="multipart/form-data" onSubmit={handleSubmit} > 
                <input type="hidden" name="_subject" value="Contact form submitted" />
                <fieldset>
                  <label>{md.registerFormNameLabel}</label>
                  <input type="text" name="name" placeholder=" " required="required"  /> 
                </fieldset>
                <fieldset>
                  <label>{md.registerFormEmailLabel}</label>
                  <input type="email" name="_replyto" placeholder=" " required="required" />
                </fieldset>
                <input type="submit" value="Sign up" />
                </form>
  */}
                <p className={registerStyles.registerOverlayFormHelpText}>{md.registerFormHelpTextLine1}</p>
                <p className={registerStyles.registerOverlayFormHelpText}>{md.registerFormHelpTextLine2}<a className={registerStyles.phoneLink} href={phoneOne}>{md.phoneOne}</a></p>
              </div>
              <div className={registerStyles.hidden + ' ' + registerStyles.registerOverlayConfirmationWrap}>
                <h4>{md.registerFormConfirmationHeader}</h4>
                <p className={registerStyles.registerOverlayFormSubheader}>{md.registerFormConfirmationSubheader}</p>
                <a onClick={closeOverlay} className={registerStyles.registerOverlayBtnClose} href="javascript:void(0)" title="Close Overlay" ></a>
              </div>
            </div>
            
          </div>
        </div>
      )
    }}
  />
)

export default Register

