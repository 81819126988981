import React from 'react';
import AppointmentButton from '../components/appointment-button';
import RegisterButton from '../components/register-button';

import { Helmet } from 'react-helmet';

import { StaticQuery, Link, graphql } from 'gatsby';

import headerStyles from '../styles/header.module.scss';
import registerStyles from '../styles/register.module.scss';

const Header = (props) => (
	<StaticQuery
		query={graphql`
			query {
				markdownRemark(frontmatter: { page: { eq: "index" } }) {
					frontmatter {
						title
						titleSuffix
						tagline
						ownerLabel
						ownerName
						ownerTitle
						appointTitleText
						portalLink
						portalTitleText
						phoneOne
						registerTitleText
					}
				}
			}
		`}
		render={(data) => {
			const md = data.markdownRemark.frontmatter;
			const meta_description = md.ownerName + ', ' + md.ownerTitle + ' - ' + md.tagline;

			return (
				<div className={headerStyles.header} style={{}}>
					<Helmet>
						<meta charSet='utf-8' />
						<title>{props.siteTitle}</title>
						<meta name='description' content={meta_description} />
						<meta
							name='keywords'
							content='tax, taxes, tax preparation, collection, IRS, internal revenue service, offer'
						/>
					</Helmet>
					<h1>
						<Link
							to='/'
							style={{
								color: `white`,
								textDecoration: `none`,
							}}
						>
							{md.title} <span>{md.titleSuffix}</span>
						</Link>
					</h1>

					<div className={headerStyles.tagline} dangerouslySetInnerHTML={{ __html: md.tagline }} />

					<div className={headerStyles.ownerLabel}>{md.ownerLabel}</div>
					<div className={headerStyles.ownerName}>{md.ownerName}</div>
					<div className={headerStyles.ownerTitle}>{md.ownerTitle}</div>
					<nav>
						<AppointmentButton className={headerStyles.btnAppoint} title={md.appointTitleText} />
						<a href={md.portalLink} target='_blank' rel='noopener noreferrer' title={md.portalTitleText}>
							{md.portalTitleText}
						</a>
						<RegisterButton
							className={headerStyles.btnRegister}
							title={md.registerTitleText}
							overlayClass={registerStyles.registerOverlayMainWrap}
							hiddenClass={registerStyles.hidden}
						/>
					</nav>
				</div>
			);
		}}
	/>
);

export default Header;
