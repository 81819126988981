import React from 'react'


 
export default class RegisterButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const openOverlay = (e) => {
      console.log('open overlay');
      const overlayWrap = document.querySelector('.'+this.props.overlayClass);
      const body = document.getElementsByTagName('body');

      overlayWrap.classList.remove(this.props.hiddenClass);
      body[0].classList.add('overlay');
    }
    return (
      <button 
        className={this.props.className} type="button" onClick={openOverlay}
        title={this.props.title}
      >
      </button>
    )
  }
}