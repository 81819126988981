import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import contactStyles from '../styles/contact.module.scss'

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: {page: {eq: "index"}}) {
          frontmatter {
            title
            titleSuffix
            contactLabel
            phoneLabel
            phoneOne
            phoneTwo
            faxLabel            
            fax
            emailLabel
            email
            addressLabel
            streetAddress
            streetAddressTwo
            cityStateZip
            

          }
          excerpt(format: HTML)
        }
      }
      `
    }

    render={ (data) => {
      const md = data.markdownRemark.frontmatter;
      const mailto = "mailto:"+md.email
      const phoneOne = "tel:"+md.phoneOne;
      const phoneTwo = "tel:"+md.phoneTwo;

      return (
        <div className={contactStyles.contact}
          style={{
          }}
        >
          <h2 className={contactStyles.h2}>{md.contactLabel}</h2>
          <div className={contactStyles.firstCol}>
            <div className={contactStyles.phoneWrap}>
              <div className={contactStyles.phoneLabel}>{md.phoneLabel}</div>
              <div className={contactStyles.phone}>
                <div><a href={phoneOne}>{md.phoneOne}</a></div>
                <div className={contactStyles.phoneConcat}>or</div>
                <div><a href={phoneTwo}>{md.phoneTwo}</a></div>
              </div>
              <br />
            </div>
            <div className={contactStyles.faxWrap}>
              <div className={contactStyles.faxLabel}>{md.faxLabel}</div>
              <div className={contactStyles.fax}>{md.fax}</div>
              <br />
            </div>
            <div className={contactStyles.emailWrap}>
              <div className={contactStyles.emailLabel}>{md.emailLabel}</div>
              <div className={contactStyles.email}>
                <a href={mailto}>{md.email}</a>
              </div>
              <br className="clear" />
            </div>
          </div>
          <div className={contactStyles.lastCol}>
            <div className={contactStyles.addressWrap}>
              <div className={contactStyles.addressLabel}>{md.addressLabel}</div>
              <div className={contactStyles.address}>
                <div>{md.title} <span>{md.titleSuffix}</span></div>
                <div>{md.streetAddress}</div>
                <div>{md.streetAddressTwo}</div>
                <div>{md.cityStateZip}</div>
              </div>
              <br className="clear" />
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default Contact

