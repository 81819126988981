import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Contact from './contact'
import Register from './register'

import '../styles/layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="appWrap">
        <div className="contentWrap"
          style={{
            padding: `0`,
            paddingTop: '0',
          }}
        >
          <Header siteTitle={data.site.siteMetadata.title} />
          <Contact></Contact>
        </div>          
        <Register></Register>
      </div>
    )}
  />
)

export default Layout
